<template>
  <div>

    <div>
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
<!--                  <vs-button color="danger" icon-pack="feather" icon="icon-x" @click="clearRows" class="py-2 px-3">Clear</vs-button>-->
<!--              <vs-button color="danger" icon-pack="feather" icon="icon-x" @click="clearRows" :disabled="data.aktifitas.length < 1" class="py-2 px-3">Clear</vs-button>-->
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
          </div>
        </div>

        <vs-table :data="rowsAktifitas" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">No. Aktifitas</vs-th>
            <vs-th class="whitespace-no-wrap">Staf Marketing</vs-th>
            <vs-th class="whitespace-no-wrap">Status</vs-th>
            <vs-th class="whitespace-no-wrap">Hubungi Via</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl. Mulai</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl. Selesai</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td><vs-input type="text" v-model="item.no_bukti" placeholder="Generate Otomatis" readonly/></vs-td>
              <vs-td><vs-input type="text" v-model="item.nama_staf" readonly/></vs-td>
              <vs-td>
                <vs-select v-model="item.status" autocomplete>
                  <vs-select-item v-for="(it, index) in statuses" :key="index" :value="it" :text="it"/>
                </vs-select>
              </vs-td>
              <vs-td>
                <vs-select v-model="item.hub_prospek" autocomplete>
                  <vs-select-item v-for="(it, index) in hubProspeks" :key="index" :value="it" :text="it"/>
                </vs-select>
              </vs-td>
              <vs-td>
                <vx-input-group>
                  <flat-pickr :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" v-model="item.tgl_mulai"></flat-pickr>
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                    </div>
                  </template>
                </vx-input-group>
              </vs-td>
              <vs-td>
                <vx-input-group>
                  <flat-pickr :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" v-model="item.tgl_selesai"></flat-pickr>
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                    </div>
                  </template>
                </vx-input-group>
              </vs-td>
              <vs-td><vs-input type="text" v-model="item.keterangan"/></vs-td>
            </vs-tr>

          </template>
        </vs-table>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TabAktifitas',
  props: {
    initData: { default: null, type: Object }, // for edit
    isActive: { default: null, type: Object },
    item: { default: null, type: Object }
  },
  components: {
    flatPickr,
    ValidationErrors
  },
  mounted () {
    // this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.aktifitasMarketingAdd.errors.tabAktifitas.messages
    },
    user () {
      return this.$store.state.auth.user
    },
    rowsAktifitas () {
      const search = this.search
      const items = this.data.aktifitas
      if (search != null) {
        return _.filter(items, (item) => {
          return item.no_bukti.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = { ..._.cloneDeep(newVal), deletedIds: [] }
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      modalCustomerProspek: {
        rowUuid: null, // uuid from this.data.aktifitas.items[n].uuid
        active: false
      },
      data: {
        hub_prospek: '',
        status: '',
        tgl_mulai: moment().format('YYYY-MM-DD H:mm'),
        tgl_selesai: moment().format('YYYY-MM-DD H:mm'),
        id_customer_prospek: null,
        aktifitas: [],
        deletedIds: []
      },
      hubProspeks: ['Call', 'Meet', 'SMS', 'WA', 'Email'],
      statuses: ['COLD', 'WARM', 'HOT'] // 'CLOSING DEAL', 'BATAL' : Otomatis berubah di sistem, dan tidak bisa manual
    }
  },
  methods: {
    addRow () {
      const row = {
        uuid: uuid(),
        id_staf: this.user.staf.id,
        nama_staf: this.user.staf.nama,
        status: '',
        tgl_mulai: moment().format('YYYY-MM-DD H:mm'),
        tgl_selesai: '',
        hubungi_via: '',
        keterangan: ''
      }
      this.search = null
      this.data.aktifitas.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.aktifitas, item => item.uuid === uuid)
      const id = this.data.aktifitas[index].id
      if (id) {
        this.data.deletedIds.push(id)
      }
      this.data.aktifitas.splice(index, 1)
    },

    // clearRows () {
    //   this.$vs.dialog({
    //     ...this.confirmOptionsClearRows(),
    //     accept: () => {
    //       this.data.aktifitas = []
    //     }
    //   })
    // },

    confirmOptionsClearRows () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Aktifitas Marketing akan dihapus semua?',
        acceptText: 'Ya',
        cancelText: 'Batal'
      }
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_TAB_HEADER', payload)
    }, 500),

    next () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
