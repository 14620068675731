import _ from 'lodash'

const errors = {
  tabAktifitas: {
    isError: false,
    messages: null
  },
  tabCustomer: {
    isError: false,
    messages: null
  },
  tabPekerjaan: {
    isError: false,
    messages: null
  }
}

const getDefaultState = () => {
  return {
    // global
    activeTab: 0,
    errors: errors,
    isAnyUnsavedChanges: false,

    // tab aktifitas
    tabAktifitas: {},

    // tab customer
    tabCustomer: {},

    // tab pekerjaan
    tabPekerjaan: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_ERRORS (state, payload) {
      const errors = payload
      const tabAktifitasErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabAktifitas'))
      const tabCustomerErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabCustomer'))
      const tabPekerjaanErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabPekerjaan'))

      state.errors.tabAktifitas.messages = tabAktifitasErrors
      state.errors.tabCustomer.messages = tabCustomerErrors
      state.errors.tabPekerjaan.messages = tabPekerjaanErrors

      state.errors.tabAktifitas.isError = !_.isEmpty(tabAktifitasErrors)
      state.errors.tabCustomer.isError = !_.isEmpty(tabCustomerErrors)
      state.errors.tabPekerjaan.isError = !_.isEmpty(tabPekerjaanErrors)
    },
    CLEAR_ERRORS (state) {
      state.errors = _.cloneDeep(errors)
    },
    SET_ANY_UNSAVED_CHANGES (state, isAnyUnsavedChanges) {
      state.isAnyUnsavedChanges = isAnyUnsavedChanges
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
    SET_TAB_HEADER (state, payload) {
      state.tabAktifitas = payload
    },
    SET_TAB_CUSTOMER (state, payload) {
      state.tabCustomer = payload
    },
    SET_TAB_PEKERJAAN (state, payload) {
      state.tabPekerjaan = payload
    }
  },

  actions: {}
}
