<template>
  <div>

    <div>
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Profesi</label>
            <v-select :options="profesis" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.customer.id_profesi" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Status Profesi" v-model="data.customer.status_profesi" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Jabatan" v-model="data.customer.jabatan" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="NPWP" type="number" v-model="data.customer.npwp" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label="Alamat NPWP" v-model="data.customer.alamat_npwp" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label="Alamat Surat" v-model="data.customer.alamat_surat" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="No. Rekening" type="number" v-model="data.customer.no_rekening" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Nama Instansi" v-model="data.customer.nama_instansi" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label="Telp. Instansi" v-model="data.customer.telp_instansi" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-printer" label="Fax Instansi" v-model="data.customer.fax_instansi" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="CP Instansi" v-model="data.customer.cp_instansi" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Jenis Usaha" v-model="data.customer.jenis_usaha" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label="Alamat Instansi" v-model="data.customer.alamat_instansi" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Penghasilan Tetap</label>
            <v-money class="w-full" label="Penghasilan Tetap" type="number" v-model="data.customer.pengh_tetap" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Penghasilan Tambahan</label>
            <v-money class="w-full" label="Penghasilan Tambahan" type="number" v-model="data.customer.pengh_tambah" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Penghasilan Bulanan</label>
            <v-money class="w-full" label="Penghasilan Bulanan" type="number" v-model="data.customer.pengh_bulanan" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'
import VMoney from '@/views/components/v-money/VMoney'
import ProfesiRepository from '@/repositories/master/profesi-repository'
import vSelect from 'vue-select'

export default {
  name: 'TabPekerjaan',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    VMoney,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProfesi()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.aktifitasMarketingAdd.errors.tabPekerjaan.messages
    },
    tabCustomer () {
      return this.$store.state.marketing.aktifitasMarketingAdd.tabCustomer
    },
    isCustomerProspekSelected () {
      return this.tabCustomer.id_customer_prospek != null
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    },
    'tabCustomer.id_customer_prospek' (newVal) {
      this.data.customer = newVal ? _.cloneDeep(this.tabCustomer.customer) : {}
    }
  },
  data () {
    return {
      data: {
        customer: {}
      },
      profesis: []
    }
  },
  methods: {
    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_TAB_PEKERJAAN', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
