<template>
  <div>

    <div>
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" @input="onChangeProyek" :reduce="item => item.id" :clearable="false"  v-model="data.id_proyek" disabled/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Kode Prospek Customer *</label>
            <vx-input-group>
              <vs-input :value="data.kode_customer_prospek" @click="modalCustomerProspek.active = true" disabled/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalCustomerProspek.active = true" disabled/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Kode Customer" v-model="data.customer.kode" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="No. Identitas" v-model="data.customer.no_identitas" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Nama" v-model="data.customer.nama" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Jenis Kelamin *</label>
            <vs-select v-model="data.customer.gender" class="w-full" :disabled="!isCustomerProspekSelected">
              <vs-select-item v-for="(it, index) in [{gender: 'L', nama: 'Laki-Laki'}, {gender: 'P', nama: 'Perempuan'}]" :key="index" :value="it.gender" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Agama</label>
            <vs-select v-model="data.customer.agama" class="w-full" :disabled="!isCustomerProspekSelected">
              <vs-select-item v-for="(it, index) in ['Islam', 'Protestan', 'Katolik', 'Hindu', 'Buddha', 'Khonghucu']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="HP" v-model="data.customer.hp" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Telp" v-model="data.customer.telp" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="E-mail *" v-model="data.customer.email" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Kota *" v-model="data.customer.kota" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Kelurahan" v-model="data.customer.kelurahan" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <vs-input class="w-full" label="Kode Pos" v-model="data.customer.pos" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Alamat *" v-model="data.customer.alamat" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl.Lahir</label>
            <flat-pickr class="w-full" v-model="data.customer.tgl_lahir" :disabled="!isCustomerProspekSelected"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Tempat Lahir" v-model="data.customer.tmp_lahir" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Tujuan Pembelian" v-model="data.customer.tujuan_pembelian" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <vs-input class="w-full" label="Sumber Dana" v-model="data.customer.sumber_dana" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Status Nikah" v-model="data.customer.status_nikah" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Nama Pasangan" v-model="data.customer.nama_pasangan" :disabled="!isCustomerProspekSelected"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Status Tempat Tinggal" v-model="data.customer.status_tmptgl" :disabled="!isCustomerProspekSelected"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <div class="flex">
              <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left" class="mr-3">Kembali</vs-button>
              <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modal customer prospek-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Prospek Customer"
              :active="modalCustomerProspek.active"
              v-on:update:active="modalCustomerProspek.active = $event">
      <CustomerProspek :selectable="true" :externalFilter="filterCustomerProspek" @selected="onSelectedModalCustomerProspek"/>
    </vs-popup>

  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ProyekRepository from '@/repositories/master/proyek-repository'
import vSelect from 'vue-select'
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'TabCustomer',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    CustomerProspek: () => import('@/views/pages/marketing/customer-prospek/CustomerProspek'),
    ValidationErrors,
    flatPickr,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.aktifitasMarketingAdd.errors.tabCustomer.messages
    },
    filterCustomerProspek () {
      return this.data.id_proyek ? { id_proyek: this.data.id_proyek } : null
    },
    isCustomerProspekSelected () {
      return this.data.id_customer_prospek !== null
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      modalCustomerProspek: {
        active: false
      },
      data: {
        id_proyek: null,
        id_customer_prospek: null,
        customer: {}
      },
      proyeks: []
    }
  },
  methods: {
    onChangeProyek () {
      this.data.id_customer_prospek = null
      this.data.kode_customer_prospek = null
      this.data.customer = {}
    },

    onSelectedModalCustomerProspek (item) {
      this.data.id_customer_prospek = item.id
      this.data.kode_customer_prospek = item.kode
      this.data.customer = item.customer
      this.modalCustomerProspek.active = false
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_TAB_CUSTOMER', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ACTIVE_TAB', 0)
    },

    next () {
      this.$store.commit('marketing/aktifitasMarketingAdd/SET_ACTIVE_TAB', 2)
    }
  }
}
</script>
